import React, { useEffect, useState } from "react";

import classNames from "classnames";
import { graphql, navigate } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import SanityImage from "gatsby-plugin-sanity-image";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../components/Button/Button";
import Consent from "../../components/consent/consent";
import ContentContainer from "../../components/ContentContainer/ContentContainer";
import ImageTextGrid from "../../components/ImageTextGrid/ImageTextGrid";
import Masonry from "../../components/Masonry/Masonry";
import Section from "../../components/Section/Section";
import Sharing from "../../components/Sharing/Sharing";
import {
  useAddToUserCollectionMutation,
  useFetchUserCollectionQuery,
  useRemoveFromUserCollectionMutation,
} from "../../features/api/netlifyApiSlice";
import { setIsAuthenticated, setUser } from "../../features/IdentitySlice";
import useMediaQuery from "../../hooks/useMediaQuery";
import {
  AddIcon24, DeleteIcon24, ImageAddIcon36, LogoutIcon24,
} from "../../icons";
import ArtworkCard from "../Artwork/ArtworkCard";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import ImageSlider from "../ImageSlider/ImageSlider";
import MainLayout from "../layouts/MainLayout";

// eslint-disable-next-line import/order
import { useLocation } from "@reach/router";

const UserCollectionPage = ({ data: { page } }) => {
  const {
    isAuthenticated,
    user,
  } = useSelector((state) => state.identitySlice);

  const userCollections = useFetchUserCollectionQuery(user.id, {
    skip: typeof user.id === "undefined",
  });
  const [removeFromUserCollection] = useRemoveFromUserCollectionMutation();

  const [identityLoaded, setIdentityLoaded] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width: 1024px)");
  const { t } = useTranslation();
  const { language } = useI18next();

  useEffect(() => {
    const interval = setInterval(() => {
      if (typeof netlifyIdentity != "undefined") {
        netlifyIdentity.on("login", () => {
          window.location.reload();
        });

        netlifyIdentity.on("logout", () => {
          dispatch(setIsAuthenticated(false));
          window.location.reload();
        });

        if (netlifyIdentity.currentUser()) {
          dispatch(setUser(netlifyIdentity.currentUser()));
          dispatch(setIsAuthenticated(true));
        } else {
          dispatch(setUser({}));
        }

        setIdentityLoaded(true);

        clearInterval(interval);
      }
    }, 10);
  }, [dispatch]);

  return (
    <MainLayout breadcrumb={<Breadcrumb id={page.id} />}>
      {!isAuthenticated && (
        <Section>
          <ContentContainer>
            <h1>{page.title}</h1>
          </ContentContainer>
          <div className="-mt-8 lg:-mt-18">
            <div className="relative">
              <div className="slide-image flex aspect-[16/9]">
                {page.slider?.elements[0]?.image?.asset?.gatsbyImageData && (
                  <GatsbyImage
                    image={page.slider?.elements[0]?.image?.asset?.gatsbyImageData}
                    alt={page.slider?.elements[0]?.image?.alt}
                    layout="fullWidth"
                  />
                )}
              </div>
              <div className="slide-info mt-30 p-18 lg:mt-0 lg:left-0 lg:bottom-0 lg:bg-white lg:absolute lg:w-5/12">
                <div className="lg:px-42 lg:pt-48 lg:pb-24">
                  <div className="h4 mb-24">{t("userCollectionTeaserText")}</div>
                  <Consent type="default" text={t("consentLoginDescription")}>
                    {identityLoaded && (
                      <Button
                        title={netlifyIdentity?.currentUser() ? t("logout") : t("signIn")}
                        onClick={() => netlifyIdentity?.open()}
                        variant="primary"
                        color="white-bordered"
                      >
                        {netlifyIdentity?.currentUser() ? <LogoutIcon24 /> : ""}
                        {netlifyIdentity?.currentUser() ? t("logout") : t("signIn")}
                      </Button>
                    )}
                  </Consent>
                </div>
              </div>
            </div>

          </div>
        </Section>
      )}

      {isAuthenticated && (

        <>
          <ContentContainer>

            <Section>
              <h1>{page.title}</h1>
              {userCollections.isSuccess && userCollections.data.length > 0 && (
                <div className="grid grid-cols-12 gap-4">
                  <div className="col-start-4 col-span-8 ...">
                    <Sharing
                      shareTitle={page.title}
                      shareUrl={`${location.host}/sammlung-teilen?id=${user.id}`}
                      title={t("shareCollectionText")}
                    />
                  </div>
                </div>
              )}
            </Section>

            {userCollections.isLoading && (
              <Section>
                <div className="grid gap-30 grid-cols-2 lg:grid-cols-3 lg:gap-60">
                  <div>
                    <div className="text-body2 mb-3 mt-18"><Skeleton height={250} /></div>
                    <div className="text-body2 mb-3 mt-18"><Skeleton height={18} width={100} /></div>
                    <h5><Skeleton width={170} /></h5>
                    <Skeleton height={40} />
                  </div>
                  <div>
                    <div className="text-body2 mb-3 mt-18"><Skeleton height={250} /></div>
                    <div className="text-body2 mb-3 mt-18"><Skeleton height={18} width={100} /></div>
                    <h5><Skeleton width={170} /></h5>
                    <Skeleton height={40} />
                  </div>
                  <div>
                    <div className="text-body2 mb-3 mt-18"><Skeleton height={250} /></div>
                    <div className="text-body2 mb-3 mt-18"><Skeleton height={18} width={100} /></div>
                    <h5><Skeleton width={170} /></h5>
                    <Skeleton height={40} />
                  </div>
                  <div>
                    <div className="text-body2 mb-3 mt-18"><Skeleton height={250} /></div>
                    <div className="text-body2 mb-3 mt-18"><Skeleton height={18} width={100} /></div>
                    <h5><Skeleton width={170} /></h5>
                    <Skeleton height={40} />
                  </div>
                  <div>
                    <div className="text-body2 mb-3 mt-18"><Skeleton height={250} /></div>
                    <div className="text-body2 mb-3 mt-18"><Skeleton height={18} width={100} /></div>
                    <h5><Skeleton width={170} /></h5>
                    <Skeleton height={40} />
                  </div>
                  <div>
                    <div className="text-body2 mb-3 mt-18"><Skeleton height={250} /></div>
                    <div className="text-body2 mb-3 mt-18"><Skeleton height={18} width={100} /></div>
                    <h5><Skeleton width={170} /></h5>
                    <Skeleton height={40} />
                  </div>
                  <div>
                    <div className="text-body2 mb-3 mt-18"><Skeleton height={250} /></div>
                    <div className="text-body2 mb-3 mt-18"><Skeleton height={18} width={100} /></div>
                    <h5><Skeleton width={170} /></h5>
                    <Skeleton height={40} />
                  </div>
                </div>
              </Section>
            )}

          </ContentContainer>
          {userCollections.isSuccess && (
            <>
              {userCollections.data.length === 0 && (
                <div className="grid grid-cols-10 mb-90">
                  <div className="col-span-10 lg:col-start-4 lg:col-span-7 border-1.5 border-dashed">
                    <div className="m-18">
                      <ImageAddIcon36 />
                      <h2 className="text-h4 mt-20">{t("noArtworksSelected")}</h2>
                      <div className="my-18">
                        {t("noArtworksSelectedDescription")}
                      </div>
                      <Button
                        className="mr-10"
                        title={t("toCollection")}
                        size="small"
                        onClick={() => {
                          navigate("/sammlung");
                        }}
                      >
                        {t("toCollection")}
                      </Button>
                    </div>
                  </div>
                </div>
              )}{userCollections.data.length !== 0 && (

                <div className="grid grid-cols-10 mb-90 mx-18">
                  <div className="col-span-10 lg:col-span-2">
                    <h5 className="mb-18">
                      {`${(new Intl.NumberFormat(language).format(userCollections.data.length))} ${t("artworks")}`}
                    </h5>
                  </div>
                  <div className="col-span-10 lg:col-span-8">
                    <Masonry breakpointCols={isMobile ? 2 : 3}>
                      { userCollections.data.map((artwork, index) => (
                        <ArtworkCard
                          key={artwork.id}
                          data={artwork}
                          className={classNames({ "mt-60": index === 0 || index === 2 })}
                          cta={(
                            <Button
                              title={t("remove")}
                              color="black"
                              size="large"
                              variant="secondary"
                              className="uppercase mt-12"
                              onClick={
                                  () => removeFromUserCollection({ artwork, userId: user.id })
                                }
                            >
                              <DeleteIcon24 /> {t("remove")}
                            </Button>
                            )}
                        />
                      ))}
                    </Masonry>
                  </div>
                </div>
              )}
            </>
          )}
        </>

      )}
      {!isAuthenticated && page.instructions?.elements?.length > 0 && (
        <Section>
          <ImageTextGrid headline={t("howItWorks")}>
            {page.instructions?.elements?.map(
              (element) => {
                if (element.image) {
                  return (
                    element.image?.asset && (
                      <SanityImage
                        {...element.image}
                        width={800}
                      />
                    )
                  );
                }

                if (element.text) {
                  return (
                    <>
                      <h3>{element.headline}</h3>
                      {element.text}
                    </>
                  );
                }

                return null;
              },
            )}
          </ImageTextGrid>
        </Section>
      )}
    </MainLayout>
  );
};

export const query = graphql`
  query ($id: String!) {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    page: sanityUserCollectionOverview(id: {eq: $id}) {
      id
      title
      slider {
        ...ImageSlider
      }
      instructions {
        elements {
          headline
          text
          image {
            ...ImageWithPreview
            alt
          }
        }
      }
      Metadata {
        ...MetaData
      }
    }
  }
`;

UserCollectionPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default UserCollectionPage;
